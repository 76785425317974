import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDepartment } from "../../slices/departement";
import { fetchRole } from "../../slices/role";
import { optionsStatus } from "../../utilities/constants";
import DatePicker from "../../components/Form/DatePicker";
import Select from "../../components/Form/Select";
import Input from "../../components/Form/Input";
import getAccess from "../../utilities/getAccess";

const ProInfo = (props) => {
  const {
    user,
    formik,
    otherstyle,
    setDepartment,
    setStatus,
    setRole,
    departement,
    status,
    Role,
    disabled,
    updateUserTest,
    addUser,
  } = props;
  const dispatch = useDispatch();
  const { department } = useSelector((state) => state?.department);
  const role = useSelector((state) => state?.role?.role);
  const rolesWithoutSuperAdmin = role?.roles?.filter(
    (role) => role?.code !== "SUPER ADMIN"
  );
  const currentUser = useSelector((state) => state?.user?.fetchMe);

  const RoleOptions =
    role?.length !== 0
      ? rolesWithoutSuperAdmin?.map((ROLE) => ({
          label: ROLE?.code,
          value: ROLE?._id,
        }))
      : [{ label: user?.role?.code, value: user?.role?._id }];

  const departmentOptions =
    department?.length !== 0
      ? department?.map((DEPARTMENT) => ({
          label: DEPARTMENT?.name,
          value: DEPARTMENT?._id,
        }))
      : [{ label: user?.department?.name, value: user?.department?._id }];

  useEffect(() => {
    if (currentUser.role.code !== "EMPLOYEE") {
      if (
        getAccess(
          currentUser?.permissions,
          currentUser?.permissionGroups?.[0]?.permissions,
          "DEPARTMENT",
          "LIST"
        )
      ) {
        dispatch(fetchDepartment());
      }
      if (
        getAccess(
          currentUser?.permissions,
          currentUser?.permissionGroups?.[0]?.permissions,
          "ROLE",
          "LIST"
        )
      ) {
        dispatch(fetchRole());
      }
    }
  }, [currentUser]);

  return (
    <>
      <DatePicker
        calendarPopperPosition="bottom"
        isDisabled={disabled ? true : false}
        formik={formik}
        type="date"
        label="Hiring Date"
        name="hiringDate"
        errorWraper="errorWraper-add-user"
        error={
          formik.errors.hiringDate && formik.touched.hiringDate
            ? formik.errors.hiringDate
            : null
        }
      />
      <Input
        isDisabled={disabled ? true : false}
        type="text"
        label="Job Title"
        name="jobTitle"
        errorWraper="errorWraper-add-user"
      />

      <Select
        isDisabled={disabled ? true : false}
        label="Department"
        id="department"
        resetValue={departement}
        setResetValue={setDepartment}
        options={departmentOptions}
        value={formik.values.department}
        onChange={(value) => formik.setFieldValue("department", value.value)}
        error={
          formik.errors.department && formik.touched.department
            ? formik.errors.department
            : null
        }
        className={`${
          formik.errors.department && formik.touched.department
            ? "selectForm  error-select  "
            : otherstyle
            ? " otherstyle selectForm "
            : " selectForm"
        }`}
      />

      <Select
        isDisabled={disabled ? true : false}
        label="status"
        id="status"
        options={optionsStatus}
        resetValue={status}
        setResetValue={setStatus}
        value={formik.values.status}
        onChange={(value) => {
          formik.setFieldValue("status", value.label);
        }}
        error={
          formik.errors.status && formik.touched.status
            ? formik.errors.status
            : null
        }
        className={`${
          formik.errors.status && formik.touched.status
            ? "selectForm  error-select"
            : otherstyle
            ? " otherstyle selectForm"
            : "selectForm"
        }`}
      />

      <Select
        isDisabled={disabled ? true : false}
        label="Role"
        id="role"
        resetValue={Role}
        setResetValue={setRole}
        options={RoleOptions}
        value={formik.values.role}
        onChange={(value) => formik.setFieldValue("role", value.value)}
        error={
          formik.errors.role && formik.touched.role ? formik.errors.role : null
        }
        className={`${
          formik.errors.role && formik.touched.role
            ? "selectForm  error-select  "
            : otherstyle
            ? " otherstyle selectForm "
            : "selectForm"
        }`}
      />

      <Input
        type="number"
        min="0"
        label="Monthly Leaves balance (Days)"
        step="0.01"
        name="balance"
        errorWraper="errorWraper-add-user"
        isDisabled={disabled ? true : false}
      />

      <Input
        type="number"
        min="0"
        label="Monthly Authorisation balance (Hours)"
        name="authorisationBalance"
        errorWraper="errorWraper-add-user"
        isDisabled={disabled ? true : false}
        step="0.01"
      />

      {(updateUserTest || addUser) && (
        <Input
          type="number"
          label="Leave Days in Stock"
          name="leavesInStock"
          errorWraper="errorWraper-add-user"
          step="0.01"
        />
      )}
    </>
  );
};

export default ProInfo;
